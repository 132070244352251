export function numberWithCommas(x, commas) {
  if (typeof x !== "number" || x.toString().length < 4) return x;
  if (
    x
      .toFixed(2)
      .toString()
      .includes(".00")
  )
    x = Math.floor(x);
  let decimal = x.toString().split('.')
  if (decimal.length > 1) {
    x = roundMoney(x)
    decimal = x.toString().split('.')
    return decimal[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, commas) + '.' + decimal[1]
  } else {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, commas)
  }
}

function roundMoney(num, digit= 2) {
  return +(Math.round(num + `e+${digit}`) + `e-${digit}`);
}

export function convertSecondsToHour(seconds) {
  return (seconds / 60 / 60).toFixed(2)
}
