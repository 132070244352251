<script>
import { Bar } from "vue-chartjs";
import moment from "moment";
export default {
  name: "BarChart",
  extends: Bar,
  props: {
    data: {
      type: Array,
      default: null,
    },
    currentMonth: {
      type: Object,
      default: null,
    },
    numberic: {
      type: Boolean,
      default: true,
    },
    currentTab: {
      type: Number,
      default: 2,
    },
  },
  mounted() {
    this.renderLineChart();
  },
  computed: {
    chartData: function () {
      return this.data;
    },
    endOfMonth: function () {
      let momentDay = this.currentMonth ? moment(this.currentMonth) : moment();
      return momentDay.endOf("month").daysInMonth();
    },
    isNumber: function () {
      return this.numberic;
    },
    maxData: function () {
      if (this.isNumber) {
        return 10;
      } else {
        return Math.max.apply(Math, this.dataMerge)
          ? Math.max.apply(Math, this.dataMerge)
          : 10;
      }
    },
    listDay: function () {
      return [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        this.endOfMonth === "30" ? "" : "31",
      ];
    },
  },

  methods: {
    renderColor(ind) {
      if (ind === 0) {
        return "#3399FF";
      }
      if (ind === 1) {
        return "#00CC00";
      }
      if (ind === 2) {
        return "#FF0000";
      }
      if (ind === 3) {
        return "#FFFF33";
      }
      if (ind === 4) {
        return "#99FF33	";
      }
      if (ind === 5) {
        return "#FF33FF	";
      }
      if (ind === 6) {
        return "#0033CC	";
      }
      if (ind === 7) {
        return "#888888	";
      }
      if (ind === 8) {
        return "#FFFF66";
      }
      if (ind === 9) {
        return "#FFCCFF";
      }
      if (ind === 10) {
        return "#00CC66";
      }
      if (ind === 11) {
        return "#FF6600";
      }
      if (ind === 12) {
        return "#006600";
      }
      if (ind === 13) {
        return "#003333";
      }
      if (ind === 14) {
        return "#000033";
      }
      if (ind === 15) {
        return "#6600CC";
      }
      if (ind === 16) {
        return "#FF66CC";
      }
      if (ind === 17) {
        return "#000033";
      }
      if (ind === 18) {
        return "#00FF00";
      }
      if (ind === 19) {
        return "#000011";
      }
    },
    renderLineChart: function () {
      this.renderChart(
        {
          labels: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            this.endOfMonth === "30" ? "" : "31",
          ],
          datasets: this.chartData.map((item, ind) => {
            const data = [];
            this.listDay.map((e) => {
              const find =
                item &&
                item.items &&
                item.items.length > 0 &&
                item.items.find((element) => {
                  return (
                    Number(this.$dayjs(element.date).format("DD")) == Number(e)
                  );
                });
              if (find && find.date) {
                let objectFind = {};
                if (this.currentTab === 2 || this.currentTab === 3) {
                  objectFind = find.value;
                }
                if (this.currentTab === 1) {
                  objectFind = (find.value / 60 / 60).toFixed(2);
                }
                data.push(objectFind);
              } else {
                data.push(0);
              }
            });
            return {
              label:
                (item && item.division && item.division.divison) ||
                (item && item.project && item.project.name) ||
                "All",
              backgroundColor: this.renderColor(ind),
              data: data,
            };
          }),
        },
        {
          scales: {
            xAxes: [
              {
                ticks: {},
              },
            ],
            yAxes: [
              {
                display: true,
                ticks: {
                  suggestedMin: 0,
                  beginAtZero: true,
                  suggestedMax: this.maxData,
                  callback: function (value) {
                    if (value > 999) {
                      return value
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    } else {
                      return value;
                    }
                  },
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                let label = data.datasets[tooltipItem.datasetIndex].label || "";
                if (label) {
                  label += ": ";
                }
                let yValue = tooltipItem.yLabel;
                if (yValue > 999) {
                  yValue = yValue
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
                label += yValue;
                return label;
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            onClick: false
          }
        }
      );
    },
  },
  watch: {
    data: function () {
      this.renderLineChart();
    },
  },
};
</script>

<style scoped>
#line-chart {
  height: 500px !important;
}
</style>
