<template>
  <div id="dashboard">
    <div class="heading">
      <div class="left-heading"><h2>Dashboard</h2></div>
    </div>
    <div class="tab-slider--nav index-tab">
      <ul class="tab-slider--tabs group-info">
        <li
          class="tab-slider--trigger info-item"
          rel="tab1"
          :class="currentTab === 1 ? 'active' : ''"
          @click="currentTab = 1"
        >
          <div class="tab-title">
            <div class="top-info">Average reply time to Client</div>
            <div class="bottom-info d-flex align-items-center">
              <div v-for="(time, index) in avgReplyTime" :key="index">
                {{ getSeconTime(time)
                }}{{ index === avgReplyTime.length - 1 ? "" : "/" }}
              </div>
              <span class="top-currency ml-10"> Hours</span>
            </div>
          </div>
        </li>
        <li
          class="tab-slider--trigger info-item"
          rel="tab2"
          :class="currentTab === 2 ? 'active' : ''"
          @click="currentTab = 2"
        >
          <div class="tab-title">
            <div class="top-info">Total messages Client sent to bot</div>
            <div class="row">
              <div class="bottom-info col-9 d-flex align-items-center">
                <div
                  v-for="(time, index) in totalMessageSendToBot"
                  :key="index"
                >
                  {{ time && time.value
                  }}{{ index === totalMessageSendToBot.length - 1 ? "" : "/" }}
                </div>
              </div>
              <div class="bottom-info col info">
                <router-link
                  :to="{
                    name: 'Mention',
                    query: {
                      division: selectedDivison.length === 1 && selectedDivison[0] === 0 ? null : selectedDivison,
                      project: selectedProject.length === 1 && selectedProject[0] === 0 ? null : selectedProject,
                      date: $dayjs(filter.date).format('YYYY-MM-DD'),
                    },
                  }"
                >
                  <button class="btn">
                    <InfoIcon />
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </li>
        <li
          class="tab-slider--trigger info-item"
          rel="tab3"
          :class="currentTab === 3 ? 'active' : ''"
          @click="currentTab = 3"
        >
          <div class="tab-title">
            <div class="top-info">Total messages not replied to Client</div>
            <!-- <div class="bottom-info">
              {{ totalMessageNotReply }}<span class="top-currency"></span>
            </div> -->
            <div class="row">
              <div class="bottom-info col-9 d-flex align-items-center">
                <div v-for="(time, index) in totalMessageNotReply" :key="index">
                  {{ time && time.value
                  }}{{ index === totalMessageNotReply.length - 1 ? "" : "/" }}
                </div>
              </div>
              <div class="bottom-info col info">
                <router-link
                  :to="{
                    name: 'NoReply',
                    query: {
                      division: selectedDivison.length === 1 && selectedDivison[0] === 0 ? null : selectedDivison,
                      project: selectedProject.length === 1 && selectedProject[0] === 0 ? null : selectedProject,
                      date: $dayjs(filter.date).format('YYYY-MM-DD'),
                    },
                  }"
                >
                  <button class="btn">
                    <InfoIcon />
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="heading mt-5">
      <div style="width: 30%"></div>
      <div
        class="right-heading"
        style="width: 70%; display: flex; justify-content: end"
      >
        <div class="input-group date">
          <vue-monthly-picker
            v-model="filter.date"
            :monthLabels="MONTH_LABEL"
            placeHolder="YYYY/MM"
            dateFormat="YYYY/MM"
            alignment="left"
            :clearOption="false"
            inputClass="month-picker"
            :max="maxMonth"
            @selected="changeDate"
          >
          </vue-monthly-picker>
          <CalenderIcon />
        </div>
        <div class="input-group mx-3" style="justify-content: end">
          <!-- <select v-model="selectedDivison" class="selection">
            <option value="">Division</option>
            <option
              v-for="option in divisions"
              v-bind:key="option.id"
              :value="option.id"
            >
              {{ option.divison }}
            </option> -->
          <!-- </select> -->

          <!-- <v-select
            v-model="selectedDivison"
            :options="divisions"
            multiple
            :reduce="(name) => name.id"
            label="divison"
            placeholder="Division"
            class="style-chooser"
            menu-props="auto"
            counter="2"
          >
          </v-select> -->
          <!-- <a-select
            mode="multiple"
            v-model="selectedDivison"
            style="width: 100%"
            placeholder="Division"
            :max-tag-count="1"
            :showSearch="true"
            :options="divisions"
            :select="handleSearch"
          >
          </a-select> -->
          <el-select
            v-model="selectedDivison"
            multiple
            placeholder="Division"
            collapse-tags="true"
            @change="updateDivision"
          >
            <el-option
              v-for="item in divisions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="input-group" style="justify-content: end">
          <el-select
            v-model="selectedProject"
            multiple
            placeholder="Project"
            collapse-tags="true"
            @change="update"
          >
            <el-option
              v-for="item in projects"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="chart-title">
        {{ currentTab === 1 ? "Hours" : "Message" }}
      </div>
      <line-chart
        :data="chartData"
        :currentMonth="filter.date"
        :numberic="numberic"
        :key="updateChart"
        :currentTab="currentTab"
      ></line-chart>
      <div class="chart-footer">Day</div>
    </div>
  </div>
</template>

<script>
import { SETTING_DATE_RANGE_LOCALE } from "@/utils/const";
import CalenderIcon from "@/components/Icon/CalenderIcon";
import LineChart from "@/components/LineChart";
import InfoIcon from "@/components/Icon/Info";
import * as _ from "lodash";
import { convertSecondsToHour } from "@/utils/convert";
import VueMonthlyPicker from "vue-monthly-picker";
import { MONTH_LABEL } from "@/utils/const";
import moment from "moment";

export default {
  name: "Home",
  components: {
    LineChart,
    VueMonthlyPicker,
    CalenderIcon,
    InfoIcon,
  },
  data() {
    return {
      currentTab: 2,
      filter: {
        date: moment(),
      },
      dataList: [],
      avgReplyTime: "",
      totalMessageSendToBot: "",
      totalMessageNotReply: "",
      SETTING_DATE_RANGE_LOCALE,
      MONTH_LABEL,
      divisions: [],
      projects: [],
      selectedDivison: [],
      selectedProject: [],
      maxMonth: moment(),
      dataMerge: [],
      updateChart: 0,
      user: null,
    };
  },
  created() {
    this.getDivsionlist();
    this.getDashboardTime();
    this.getDashboardMessageNotReply();
    this.getDashboardMessageSendToBot();
    this.getProjectList();
  },
  computed: {
    chartData() {
      return this.dataList;
    },
    numberic() {
      return this.currentTab !== 1;
    },
  },
  mounted() {
    this.getUser();
    // setTimeout(() => {
    //   document.getElementById('clearMonthBtn').addEventListener('click', () => {
    //     document.querySelector('#dashboard .left-heading').click()
    //     this.filter.date = moment();
    //     this.getAnalytic();
    //     this.getData()
    //   })
    // }, 500)
  },
  watch: {
    currentTab() {
      this.getData();
    },
    selectedProject() {
      this.getDashboardTime();
      this.getDashboardMessageNotReply();
      this.getDashboardMessageSendToBot();
    },
    selectedDivison() {
      this.projects = [];
      this.getProjectList();
      this.getDashboardTime();
      this.getDashboardMessageNotReply();
      this.getDashboardMessageSendToBot();
    },
    dataList: {
      deep: true,
      handler() {
        this.updateChart++;
      },
    },
  },
  methods: {
    getUser() {
      this.$root.$refs.loading.start();
      this.$store
        .dispatch("auth/getInfoUserD")
        .then((response) => {
          this.user = response;
          if (response.division_id) {
            this.selectedDivison.push(response.division_id);
            this.selectedProject = [0];
          } else {
            this.selectedDivison = [0];
            this.selectedProject = [0];
          }
          this.$root.$refs.loading.finish();
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
    updateDivision(index) {
      this.selectedDivison = [];
      for (let i = 0; i < index.length; i++) {
        if (index.includes(0)) {
          if (index[index.length - 1] === 0) {
            this.selectedDivison = [0];
            return;
          } else {
            const x = index.includes(0 && index[index.length - 1] !== 0);
            if (x) {
              const indexs = index.indexOf(0);
              if (indexs > -1) {
                index.splice(indexs, 1);
              }
              this.selectedDivison = index;
            }
          }
        } else {
          this.selectedDivison = index;
        }
      }
    },
    update(index) {
      this.selectedProject = [];
      for (let i = 0; i < index.length; i++) {
        if (index.includes(0)) {
          if (index[index.length - 1] === 0) {
            this.selectedProject = [0];
            return;
          } else {
            const x = index.includes(0 && index[index.length - 1] !== 0);
            if (x) {
              const indexs = index.indexOf(0);
              if (indexs > -1) {
                index.splice(indexs, 1);
              }
              this.selectedProject = index;
            }
          }
        } else {
          this.selectedProject = index;
        }
      }
    },
    getSeconTime(time) {
      return convertSecondsToHour(time.value);
    },
    handleSearch(value) {
      console.log(value);
    },
    changeLayOutPicker() {
      setTimeout(() => {
        let btn = document.createElement("BUTTON");
        btn.innerHTML = "リセット";
        btn.id = "clearMonthBtn";
        btn.className = "cancelBtn btn btn-sm btn-secondary clearMonthBtn";
        if (!document.getElementById("#clearMonthBtn")) {
          document
            .querySelector(".month-picker-wrapper .date-popover .picker")
            .append(btn);
        }
      }, 500);
    },
    changeDate() {
      if (this.filter.date === null) {
        this.filter.date = moment();
      }
      this.getDashboardTime();
      this.getDashboardMessageNotReply();
      this.getDashboardMessageSendToBot();
      // let dateTimeValue = 'YYYY/MM';
      // if (this.filter.date) {
      //   dateTimeValue = this.$dayjs(this.filter.date).format('YYYY/MM');
      // }
      // document.getElementById('filter-user-date-picker__value_').innerHTML = dateTimeValue
    },
    getData() {
      switch (this.currentTab) {
        case 1:
          this.getDashboardTime();
          break;
        case 2:
          this.getDashboardMessageSendToBot();
          break;
        case 3:
          this.getDashboardMessageNotReply();
          break;
        default:
          this.getDashboardDistributor();
          break;
      }
    },
    getvalueFromToDate(items, from, to) {
      return items
        .filter(
          (item) =>
            from < moment(item.date).date() && moment(item.date).date() <= to
        )
        .reduce((accumulator, object) => {
          return accumulator + object.value;
        }, 0);
    },
    getAvailiableDate(toDate, currentView) {
      const endOfMonth = moment(currentView).endOf("month");
      const currentMoment = moment();
      const currentDate = currentMoment.date();
      if (
        endOfMonth.isBefore(currentMoment) ||
        currentDate >= toDate ||
        currentDate === endOfMonth.date()
      ) {
        return true;
      }
      return false;
    },
    convertDataItemsToDataChart(items) {
      const itemsSorted = _.sortBy(items, "date");
      if (_.isEmpty(itemsSorted)) {
        return [];
      }
      const firstValue = _.first(itemsSorted);
      const firstMoment = moment(firstValue.date);
      const dataList = [];
      const valueOfFirstDay = firstMoment.date() === 1 ? firstValue.value : 0;
      dataList.push(valueOfFirstDay);
      if (this.getAvailiableDate(5, firstMoment)) {
        dataList.push(this.getvalueFromToDate(itemsSorted, 1, 5));
      }
      if (this.getAvailiableDate(10, firstMoment)) {
        dataList.push(this.getvalueFromToDate(itemsSorted, 5, 10));
      }
      if (this.getAvailiableDate(15, firstMoment)) {
        dataList.push(this.getvalueFromToDate(itemsSorted, 10, 15));
      }
      if (this.getAvailiableDate(20, firstMoment)) {
        dataList.push(this.getvalueFromToDate(itemsSorted, 15, 20));
      }
      if (this.getAvailiableDate(25, firstMoment)) {
        dataList.push(this.getvalueFromToDate(itemsSorted, 20, 25));
      }
      if (this.getAvailiableDate(30, firstMoment)) {
        dataList.push(this.getvalueFromToDate(itemsSorted, 25, 31));
      }
      return dataList;
    },
    getDashboardTime() {
      this.$root.$refs.loading.start();
      let params = {};
      if (
        this.selectedDivison.length > 0 &&
        (this.selectedProject.length === 0 ||
          (this.selectedProject.length === 1 && this.selectedProject[0] === 0))
      ) {
        params = {
          ...params,
          listDivisionIds:
            this.selectedDivison &&
            this.selectedDivison.length === 1 &&
            this.selectedDivison[0] === 0
              ? null
              : this.selectedDivison,
        };
      }

      if (this.selectedProject.length > 0) {
        params = {
          ...params,
          listProjectIds:
            this.selectedProject &&
            this.selectedProject.length === 1 &&
            this.selectedProject[0] === 0
              ? null
              : this.selectedProject,
        };
      }

      if (this.filter.date) {
        params = {
          ...params,
          time: this.$dayjs(this.filter.date).format("YYYY-MM-DD"),
        };
      }
      this.$store
        .dispatch("dashboard/getDashboardTime", params)
        .then((response) => {
          this.avgReplyTime = response;
          if (this.currentTab === 1) {
            this.dataList = response;
          }
          this.$root.$refs.loading.finish();
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
    getDashboardMessageSendToBot() {
      this.$root.$refs.loading.start();
      let params = {};
      if (this.filter.date) {
        params = {
          ...params,
          time: this.$dayjs(this.filter.date).format("YYYY-MM-DD"),
        };
      }
      if (
        this.selectedDivison.length > 0 &&
        (this.selectedProject.length === 0 ||
          (this.selectedProject.length === 1 && this.selectedProject[0] === 0))
      ) {
        params = {
          ...params,
          listDivisionIds:
            this.selectedDivison &&
            this.selectedDivison.length === 1 &&
            this.selectedDivison[0] === 0
              ? null
              : this.selectedDivison,
        };
      }

      if (this.selectedProject.length > 0) {
        params = {
          ...params,
          listProjectIds:
            this.selectedProject &&
            this.selectedProject.length === 1 &&
            this.selectedProject[0] === 0
              ? null
              : this.selectedProject,
        };
      }
      this.$store
        .dispatch("dashboard/getDashboardMessageToBot", params)
        .then((response) => {
          if (this.currentTab === 2) {
            this.dataList = response;
          }
          this.totalMessageSendToBot = response;
          this.$root.$refs.loading.finish();
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
    getDashboardMessageNotReply() {
      this.$root.$refs.loading.start();
      let params = {};
      if (this.filter.date) {
        params = {
          ...params,
          time: this.$dayjs(this.filter.date).format("YYYY-MM-DD"),
        };
      }
      if (
        this.selectedDivison.length > 0 &&
        (this.selectedProject.length === 0 ||
          (this.selectedProject.length === 1 && this.selectedProject[0] === 0))
      ) {
        params = {
          ...params,
          listDivisionIds:
            this.selectedDivison &&
            this.selectedDivison.length === 1 &&
            this.selectedDivison[0] === 0
              ? null
              : this.selectedDivison,
        };
      }

      if (this.selectedProject.length > 0) {
        params = {
          ...params,
          listProjectIds:
            this.selectedProject &&
            this.selectedProject.length === 1 &&
            this.selectedProject[0] === 0
              ? null
              : this.selectedProject,
        };
      }
      this.$store
        .dispatch("dashboard/getDashboardMessageNotReply", params)
        .then((response) => {
          if (this.currentTab === 3) {
            if (response.length > 0) {
              this.dataList = response;
            }
          }

          this.totalMessageNotReply = response;
          this.$root.$refs.loading.finish();
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
    async getDivsionlist() {
      this.$root.$refs.loading.start();
      this.$store
        .dispatch("dashboard/getListDivision", { isAll: "true" })
        .then((response) => {
          this.$root.$refs.loading.finish();
          response.unshift({
            divison: "All",
            id: 0,
          });
          this.divisions = response.map((item) => ({
            label: `${item.divison}`,
            value: item.id,
          }));
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
    async getProjectList(page = 1) {
      this.$root.$refs.loading.start();
      const params = { order: "DESC" };
      params.limit = 50;
      params.page = page;
      if (this.selectedDivison.length > 0) {
        params.listDivisionIds = this.selectedDivison;
      }
      this.$store
        .dispatch("projects/getProjectList", params)
        .then((response) => {
          response.data.unshift({
            name: "All",
            id: 0,
          });
          this.projects = this.projects.concat(response.data);

          this.$root.$refs.loading.finish();
          if (response.meta.hasNextPage) {
            this.getProjectList(page + 1);
          }
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/home.scss";
</style>
<style lang="scss">
.vue-monthly-picker .active {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.style-chooser .vs__dropdown-toggle {
  border-color: #dee2e6 !important;
  border-radius: 8px;
  width: 225px !important;
  background-color: transparent;
  color: #8b8b8b;
  font-size: 12px;
  height: 40px;
}
.legent_name {
  display: flex;
  justify-content: center;
  font-size: 12px;
}
.el-input__inner {
  width: 220px;
  height: 42px;
  color: #8b8b8b;
  padding: 6px 0 5px 6px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  background: transparent;
}
.monthItem {
  .active {
    background: none !important;
  }
  .selected {
    background: #345e98 !important;
  }
}
.chart-footer {
  display: flex;
  justify-content: end;
  font-size: 18px;
  color: #000;
  font-weight: 700;
  text-align: left;
  padding-bottom: 15px;
}
</style>
